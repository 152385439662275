<template>
  <div class='fee'>
    <div class='fee__balance'>
      <div class='fee__data-container'>
        <div class='fee__block-title'>Your balance</div>
        <div class='fee__coins-container'>
          <Icon :size="20" :type="'coin'" />
          <span class='fee__coins-count'>{{ balance }}</span>
        </div>
      </div>
      <router-link :to='{name: "coins-storage"}' class="fee__add-button">
        <Icon :size="32" type="add-circle-with-background"/>
      </router-link>
    </div>
    <div class='fee__price'>
      <div class='fee__data-container'>
        <div class='fee__block-title'>Total on Venue</div>
        <div class='fee__coins-container fee__coins-container--text-right' :style='{"text-align": "end"}'>
          <Icon :size="20" :type="'coin'" />
          <span class='fee__coins-count'>{{ price }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'

export default {
  components: { Icon },

  props: {
    balance: {
      type: Number,
    },
    price: {
      type: Number,
    }
  }
}
</script>

<style scoped lang='stylus'>
  .fee {
    display: flex;
    gap: 2px;

    &__balance {
      flex: 1;
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      padding: 12px 12px;
      background: hsla(228, 25%, 20%, 1);
      border-radius: 8px 0 0 8px;
    }

    &__price {
      flex: 1;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      padding: 12px 12px;
      background: hsla(228, 25%, 20%, 1);
      border-radius: 0 8px 8px 0;
    }

    &__add-button {
      align-self: flex-end;
      padding-right: 4px;
    }

    &__block-title {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: hsla(206, 12%, 54%, 1);
    }

    &__data-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__coins-container {
      display: flex;
      align-items: center;
      gap: 8px;

      &--text-right {
        justify-content: end;
      }
    }

    &__coins-count {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: hsla(0, 0%, 85%, 1);
    }
  }
</style>
