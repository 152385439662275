<template>
  <subview class="newcompetition-preview">
    <view-header>
      <custom-header :router-back="$goBack">
        <template v-slot:default>
          <div>
            <Icon :size="24" type="arrow-left" />

            {{ $t('pages.newcompetition.preview.title') }}
          </div>
        </template>

        <template #last>
          <div class="current-step"> {{ $t('pages.newcompetition.step') }} 4/4 </div>
        </template>
      </custom-header>
    </view-header>

    <view-content>
      <Competition :preview="true" :competition="preview" />
      <!-- <div class="competition-component">
        <div class="versus" v-if="showVersusCompetitors">
          <div class="vs-image">
            <img src="@/assets/img/imgVs.png" />
          </div>

          <Competitor v-for="competitor in competitors" :key="competitor.id" :competition="competition"
            :competitor="competitor" mode="vertical" />
        </div>

        <Competitors v-if="showAllCompetitors" :competitors="competitors" :organizer="organizer"
          :competition="competition" />

        <div class="date">
          <div class="event-date">
            <Icon :size="17" type="calendar" />

            <span> {{ date }} </span>
          </div>

          <div class="timer" v-if="timer">
            <Icon :size="17" type="time" />

            <span> {{ timer }} </span>
          </div>
        </div>
        <div v-if="competition.kind_sport.name === 'Swimming'" class="details">
          <div class="detail">
            <span class="name"> {{ $t('details.gender') }} </span>
            <span class="value"> {{ this.gender_participants }} </span>
          </div>
          <div class="detail" v-for="(value, name) in details" :key="name">
            <span class="name"> {{ $t('details.' + name) }} </span>
            <span class="value"> {{ value }} </span>
          </div>
        </div>

        <div v-if="competition.kind_sport.name === 'Basketball'" class="details">
          <span class="name"> Discipline </span>
          <span class="value"> Around the world </span>
        </div>
      </div> -->

      <div class='block'>
        <div class='block__caption'>Application Fee</div>
        <competition-fee-block :balance='balance' :price='competitionPrice' />
      </div>
    </view-content>

    <view-footer>
      <a-button :class='{ "ant-btn disabled": isNotEnoughCoins }' @click="create">
        {{ $t('pages.newcompetition.create') }}

        <Icon :size="20" type="arrow-right" />
      </a-button>
    </view-footer>
  </subview>
</template>

<script>
import Icon from '@/components/Icon'
import Competition from '@/components/Competitions/Competition'
import newcompetition from '@/mixins/newcompetition'
import CompetitionFeeBlock from '@/components/NewCompetition/CompetitionFeeBlock.vue'
import { mapGetters } from 'vuex'


export default {
  name: 'Preview',
  mixins: [
    newcompetition
  ],
  components: {
    CompetitionFeeBlock,
    Icon,
    Competition
  },
  data() {
    return {
      competitionPrice: 4,
    }
  },
  computed: {
    ...mapGetters({
      'balance': 'balance',
    }),

    showAllCompetitors() {
      return this.preview.challenges.length > 1
    },
    showVersusCompetitors() {
      return this.preview.challenges.length === 1
    },
    challenges() {
      return this.preview?.challenges || []
    },
    competitors() {
      const challenges = this.preview.challenges
      const competitors = []

      if (!challenges?.length) {
        return competitors
      }

      competitors.push(this.organizer)

      for (let challenge of challenges) {
        competitors.push(challenge.opponent)
      }

      if (this.isCompetitionHaveInvitedPersons) {
        this.competition?.invites_with_hashes.forEach(invite => {
          competitors.push(invite)
        })
      }
      return competitors
    },
    showPrice() {
      return this.competition?.fee >= 0 ? this.competition.fee : -1;
    },
    isNotEnoughCoins() {
      return this.balance < this.competitionPrice;
    }
  },
  methods: {
    create() {
      this.createCompetition()

      this.$router.push({
        name: 'newcompetition.final'
      })
    }
  },
}
</script>

<style scoped lang='stylus'>
.block {
  margin-top: 40px;

  &__caption {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: hsla(0, 0%, 85%, 1);
    margin-bottom: 8px;
  }
}
</style>
