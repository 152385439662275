import { mapState } from 'vuex'
import moment from 'moment-timezone'

const sports = [
  {
    id: 1,
    details: [
      { id: 3, related: 0 },
      { id: 1, related: 3 },
      { id: 4, related: 1 }
    ],
    appearance: [
      'venue',
      'addons',
      'sponsors',
      'judgements',
      'description'
    ]
  },
  {
    id: 3,
    appearance: [
      'venue',
      'addons',
      'sponsors',
      'judgements',
      'description'
    ]
  },
]



const routes = {
  discepline() {
    return !!this.selectedSport
  },
  date() {
    return !!this.selectedDate
  },
  details() {
    return true
    // return ? this.selectedDetails.every(({ value }) => value !== null) : true;
  },
  appearance() {
    return true
  },
  competitors() {
    return this.selectedCompetitors.length || this.invitesByLink.length
  },
  invites() {
    return true
  },
  preview() {
    return true
  }
}

function createAppearance(sport) {
  function getValue(type) {
    switch (type) {
      case 'venue':
        return sport.appearance[type]?.variations?.[0] || null

      case 'addons':
        return Object.fromEntries(sport.appearance[type]?.variations.map(({ id, values }) => [id, values?.[0]]) || {})

      case 'sponsors':
        return sport.appearance[type]?.variations?.[0] || null

      case 'judgements':
        return sport.appearance[type]?.variations?.[0] || null

      default:
        return null
    }
  }

  const appearance = {}
  const settings = sports.find(({ id }) => id === sport.id)

  for (const type of settings.appearance) {
    appearance[type] = getValue(type)
  }

  return appearance
}

function createDetails(sport) {
  const settings = sports.find(({ id }) => id === sport.id)

  return settings.details.map(({ id, related }) => { return { id, value: null, related } })
}

export default {
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user
    }),
    ...mapState('newcompetition', {
      selectedSport: ({ sport }) => sport,
      selectedDate: ({ date }) => date,
      selectedDetails: ({ details }) => details,
      selectedAppearance: ({ appearance }) => appearance,
      selectedGender: ({ gender }) => gender,
      selectedCompetitors: ({ competitors }) => competitors,
      selectedViewers: ({ viewers }) => viewers,
      selectedAddonsIds: ({ selectedAddonsIds }) => selectedAddonsIds,
      invitesByLink: ({ invite_hashes }) => invite_hashes
    }),
    preview() {
      const { user, selectedSport, selectedGender, selectedDate, selectedDetails, selectedCompetitors, selectedViewers, invitesByLink } = this
      const challengesWithUsers = selectedCompetitors.map((competitor) => { return { initiator: user, opponent: competitor } })
      const challengesByLinkInvite = invitesByLink.map((competitor) => { return { initiator: user, opponent: competitor } })
      return {
        entity: 'competition',
        challenges: [...challengesWithUsers, ...challengesByLinkInvite],
        event_at: selectedDate ? moment(selectedDate).format('YYYY-MM-DD HH:mm') : null,
        invites: selectedViewers,
        gender_participants: selectedGender,
        kind_sport: {
          id: selectedSport?.id,
          name: selectedSport?.name,
          types: selectedDetails.map((item) => {
            const { id, name, values } = selectedSport?.types.find((type) => type?.id === item?.id)

            return { id, name, values: [values.find((value) => value?.id === item.value)] }
          })
        }
      }
    },
    newcompetition() {
      const { selectedSport, selectedDate, selectedDetails, selectedAppearance, selectedGender, selectedCompetitors, selectedViewers, selectedAddonsIds, invitesByLink } = this

      return {
        description: selectedAppearance.description || '',
        event_at: selectedDate ? moment(selectedDate).utc().format('YYYY-MM-DD HH:mm') : null,
        invite_user_ids: selectedViewers.map(({ id }) => id),
        judgement_id: selectedAppearance.judgements?.id || null,
        kind_sport_detail_values: selectedDetails.map(({ value }) => (value === null) ? value = 8 : value),
        kind_sport_id: selectedSport?.id || null,
        opponent_ids: selectedCompetitors.map(({ id }) => id),
        referee_id: null,
        gender_participants: selectedGender || '',
        sponsor_id: selectedAppearance.sponsors?.id || null,
        venue_addon_values: selectedAddonsIds,
        venue_variation_id: selectedAppearance.venue?.id || null,
        invite_hashes: [...invitesByLink]
      }
    },
    isWeCanAddMoreUsers() {
      return (this.selectedSport && this.selectedSport.name === 'Swimming') ? (this.selectedCompetitors.length + this.invitesByLink.length) < 7 :
        (this.selectedCompetitors.length + this.invitesByLink.length) < 7
    }
  },
  beforeRouteEnter(to, _, next) {
    next(vm => {
      const name = Object.keys(routes).find((name) => to.name.includes(name))


      if (!name) {
        return
      }

      for (const route in routes) {
        if (route === name) {
          return
        }

        if (!routes[route].bind(vm)()) {
          console.log('im here', 'newcompetition.' + route)
          next({ name: 'newcompetition.' + route })
        }
      }
    })
  },
  methods: {
    createCompetition() {
      this.$store.dispatch('newcompetition/create', this.newcompetition)
    },
    setSport(sport) {
      this.$store.dispatch('newcompetition/setSport', sport)
      if (sport.name === "Swimming") {
        this.$store.dispatch('newcompetition/setDetails', createDetails(sport))
      }
      this.$store.dispatch('newcompetition/setAppearance', createAppearance(sport))
    },
    setDetail(id, value) {
      this.$store.dispatch('newcompetition/setDetail', { id, value })

      const related = this.selectedDetails.find(({ related }) => related === id)

      if (related) {
        this.setDetail(related.id, null)
      }
    },
    setDate(date) {
      this.$store.dispatch('newcompetition/setDate', date)
    },
    setGender(gender) {
      this.$store.dispatch('newcompetition/setGender', gender)
    },
    addCompetitor(competitor) {
      this.$store.dispatch('newcompetition/addCompetitor', competitor)
    },
    removeCompetitor(competitor) {
      this.$store.dispatch('newcompetition/removeCompetitor', competitor)
    },
    addViewer(viewer) {
      this.$store.dispatch('newcompetition/addViewer', viewer)
    },
    removeViewer(viewer) {
      this.$store.dispatch('newcompetition/removeViewer', viewer)
    },
    updateAppearance(type, value) {
      this.$store.dispatch('newcompetition/updateAppearance', { type, value })
    },
    saveAddons(value) {
      this.$store.commit('newcompetition/SET_SELECTED_ADDONS', value)
    },
    setInvitesByLink(invites) {
      this.$store.commit('newcompetition/SET_INVITES_BY_LINK', invites)
    }
  }
}
